<template>
  <div v-if="article" class="article-container">
    <h1>{{ article.title }}</h1>
    <div class="content" v-html="article.content"></div>
    <div class="dates">
        <p>{{ $t('blog.published_at') }} {{ displayDate(article.created_at) }}</p>
        <p v-if="article.updated_at">{{ $t('blog.updated_at') }} {{ displayDate(article.updated_at) }}</p>
        <div class="tags">                        
            <p v-for="(tag, index) in article.tags" :key="index">#{{ tag }}</p>
        </div>
    </div>
  </div>
</template>
  
<script>
  import { mapGetters, mapActions } from 'vuex';
  import axios from 'axios'

  export default {
    data() {
      return {
        articles: [],
      }
    },
    computed: {
      ...mapGetters(['getSelectedArticle']),
      article() {
        return this.getSelectedArticle;
      },
    },
    watch: {
      articles() {
        this.articles.forEach((article) => {
          const titleSlug = this.slugify(article.title);

          if (titleSlug === this.$route.params.url) {
            this.selectArticle(article);
          }
        })
      }
    },
    methods: {
      ...mapActions(['selectArticle']),
      displayDate(isoDate) {
        if (!isoDate) return;
        const dateObj = new Date(isoDate);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();
        return `${day}/${month}/${year}`;
      },
      slugify(text) {
        return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
      },
    },
    async mounted() {
      if (!this.article) {
        await axios.get(process.env.VUE_APP_BASE_URL + 'api/articles')
        .then((response) => {
            this.articles = response.data.articles;
        })
        .catch(() => {})
      }
    }
  };
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.article-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5vh 25vw;
    text-align: justify;
    max-width: 1200px;

    h1 {
      margin-bottom: 25px;
      text-align: initial;
    }

    img {
        width:  auto;
        margin: 4vh 0;
        max-height: 45vh;
        max-width: 95vw;
    }

    video {
      max-height: 50vh;
    }
    
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 1200px;

      p {
        width: 100%;
        text-align: left;
      }
    }

    .dates {
        margin-top: 2vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: var(--text-grey);
    }

    .tags {
        display: flex;
        gap: 5px;
        color:var(--article-text-color);
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 1200px) {
  .article-container {
    margin: 3vh 2vw;
    max-width: 850px;

    .content {
      max-width: 850px;
    }

  }
}

@media screen and (max-width: 850px) {
  .article-container {
    margin: 3vh 2vw;
    max-width: 400px;

    .content {
      max-width: 400px;
    }
  }
}

</style>